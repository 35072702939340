.form {
  &-container {
    input[type="radio"] {
      appearance: none;
      background-color: #fff;
      margin: 0;
      width: 1.875rem;
      height: 1.875rem;
      border: 0.15em solid currentColor;
      border-radius: 50%;
      display: grid;
      place-content: center;
      cursor: pointer;
      margin-right: 1rem;
      flex: none;

      &::before {
        content: "";
        width: 1.25rem;
        height: 1.25rem;
        border-radius: 50%;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em var(--form-control-color);
        background: black;
        cursor: pointer;
      }

      &:checked::before {
        transform: scale(1);
      }
    }

    input[type="text"],
    input[type="email"],
    textarea {
      border: 1px solid $darkGray;
      padding: 0.5rem 1rem;
      @apply rounded-md;

      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $darkGray;
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $darkGray;
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: $darkGray;
      }
    }
  }

  &-label {
    margin: 2rem 0 0.5rem 0;
    display: block;

    &-radio {
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
    }
  }
}